import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';




function App() {
  return (
    <div>
      <NameForm />
    </div>
  );
}


function CalcResultsElement(props) {

  console.log("CalcResultsElement")
  let calcJSON = JSON.parse('{"1": "Enter you personal values/assumptions, whole numbers only, for ROTH ladder retirement calculator and click Submit"}');
  console.log(calcJSON)
  console.log(calcJSON.length)
  if (props.calcQueryResult.length > 0) {
    console.log("parsing")
    calcJSON = JSON.parse(props.calcQueryResult);
    console.log(calcJSON)
  } 
  const elements = ['current_date','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25,26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59','60', 'out'];

  const jsonitems = []

  for (const [index, value] of elements.entries()) {
    if(calcJSON.hasOwnProperty(`${value}`)){
      jsonitems.push(<li key={index}>{calcJSON[`${value}`]}</li>)
    } 
  }
  return (
    <p>
       {jsonitems}
      {/*  */}
    </p>
  );
}

class NameForm extends React.Component {
  //calcResult = "Results...";
  constructor(props) {
    super(props);
    this.state = {value: '', models: ""};
    //this.calcResult = {value: ''};
    //var items = "result";
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    if(this.state.inflation === undefined) this.state.inflation = 3;
    if(this.state.age === undefined) this.state.age = 46;
    if(this.state.historic_return_percent === undefined) this.state.historic_return_percent = 8;
    if(this.state.current_roth === undefined) this.state.current_roth = 100000;
    if(this.state.taxable_balance === undefined) this.state.taxable_balance = 200000;
    if(this.state.ira_balance === undefined) this.state.ira_balance = 250000;
    if(this.state.spend_per_month === undefined) this.state.spend_per_month = 3000;
  }

  handleChange(event) {
    console.log("HandleChange")
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log(name)
    console.log(value)
    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {

    //fetch(`http://127.0.0.1:5000/rothladderjson/${this.state.inflation}/${this.state.age}/${this.state.historic_return_percent}/${this.state.current_roth}/${this.state.taxable_balance}/${this.state.ira_balance}/${this.state.spend_per_month}`)
    fetch(`/rothladderjson/${this.state.inflation}/${this.state.age}/${this.state.historic_return_percent}/${this.state.current_roth}/${this.state.taxable_balance}/${this.state.ira_balance}/${this.state.spend_per_month}`)
    .then(res => res.text())
    .then(
      (result) => {
        this.setState({
          isLoaded: true
        });
        this.setState({
          models: result
        });

      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
    //alert('A name was submitted: ' + this.items.value);
    event.preventDefault();
  }

  render() {
    console.log("NameForm renderCalled");
    console.log(this.state)
    console.log(this.state.inflation)
    const { isLoaded } = this.state;
    const items = this.state.models;
    //this.state.mywidth=50;
    //alert(items);
    console.log(items);
    // if (!isLoaded) {
    //   return(
    //     <form onSubmit={this.handleSubmit}>
    //     <label>
    //       Age:
    //       <input type="text" value={this.state.value} onChange={this.handleChange} />
    //     </label>
    //     <input type="submit" value="Submit" />
    //   </form>
    //   );
    // }
    return (
      <div>
        <header>
        <h2>Visit the <a href="blog">BLOG</a></h2>
        <h1>Roth Ladder Calculator</h1><h2><a class="twitter-share-button"
  href="https://twitter.com/intent/tweet?via=VilleM_DanaPt&text=Calculate%20your%20FIRE,%20Try%20Roth%20ladder%20calculator%20">
Share via Tweet</a></h2>

        </header>
        <main>
    <p>
      Use this calculator to estimate your ability to retire early via a multiyear roth ladder strategy. 
      This allows you to tap into your retirement accounts without penalty before reaching age 59½. 
      When you do an IRA to Roth IRA conversion, you must wait five years to withdraw the converted amount to avoid a 10% early withdraw penalty. 
      {/* </p>
      <p> */}
      <br></br>
      In this calculator we estimate your end of the year balances and your ability to retire at a given age   
      by first using your taxable savings and converting only a specified monthly spending amount to minimize taxes. 
      This calculator will alert if your assumptions don't allow for sufficient funds to reach age 60.
    </p>
    <p>
    <li>
      Resubmit as many times as you like with multiple scenarios to assess your ability to retire now or in the future.
    </li>
    <li>
      Do not use decimals, whole numbers only and do not rely on this calculator for final financial decision making.
    </li>
    </p>

        


        {/* <h2>ROTH ladder retirement calculator</h2> */}
        {/* <h3>"A Roth IRA conversion ladder is a multiyear strategy that allows you to tap your retirement account without penalty before reaching age 59½. When you do a Roth IRA conversion, you must wait five years to withdraw the converted amount to avoid a 10% tax hit." Jan 5, 2022 
How the Roth Conversion Ladder Works - Investopedia</h3>*/}
         {/* <li>APPROXIMATES whether your accounts can sustain an early retirement (IRA, ROTH, Savings)</li> */}
        {/* <li>APPROXIMATES and many possible incorrect assumptions are built in; for example, tax law not changing etc. Do not rely on this calculator for financial decision making</li> */}
        {/*<li>For those below the untaxable IRA age (currently set by IRS at 58.5, but for purposes of this calculator we use age 60).</li> */}
        {/* <li>Do not use decimals, WHOLE NUMBERS ONLY (APPROXIMATES, we can obviously not predict the future, especially down to decimals....)</li> */}
        {/* <li>The ROTH ladder avoids paying a 10% early IRA withdraw penalty by moving money from an IRA to a ROTH-IRA and not using this converted money for 5 years. </li> */}
      <form onSubmit={this.handleSubmit}>
        <p>
        <label>        
          $<input name="current_roth" type="text" value={this.state.current_roth} onChange={this.handleChange} />
          Roth IRA Starting balance</label>
        </p>
        <p>
        <label>        
          $<input name="ira_balance" type="text" value={this.state.ira_balance} onChange={this.handleChange} />
          IRA balance - in traditional IRA (to be converted to ROTH, you will be paying income tax on conversions)</label>
        </p>

        <p>
        <label>
          $<input name="taxable_balance" type="text" value={this.state.taxable_balance} onChange={this.handleChange} />
          Taxable balance - savings/investments in taxable accounts (available for immediate withdraw without tax penalties)</label>
        </p>
        <p>
        <label>        
          $<input name="spend_per_month" type="text" value={this.state.spend_per_month} onChange={this.handleChange} />
          Spend per month -- (housing, health, food, transportation) keep this low to minimize taxes as well</label>
        </p>   
        <p>
        <label>          
          %<input name="inflation" type="text" value={this.state.inflation} onChange={this.handleChange} />
          Inflation percent, future expected average  
          </label>  
        </p>
        <p>
        <label>        
          %<input name="historic_return_percent" type="text" value={this.state.historic_return_percent} onChange={this.handleChange} />
           Historic return percent - Future expected return on investments (ETF like VTI or similar)
        </label>
        </p>
        <p>
        <label>          
          <input name="age" type="text" value={this.state.age} onChange={this.handleChange} />
          Age in years now or when you plan to start withdraws
        </label>
        </p>

        <input type="submit" value="Submit" />
      </form>
      <br></br>
      
      <CalcResultsElement calcQueryResult={items}/>
      
      {/* <div id='calcResultDiv'>
        <p id='calcResultP'>{items}</p>
        </div> */}
        <p>
    <li>APPROXIMATES whether your accounts can sustain an early retirement (IRA, ROTH, Savings)</li>
    <li>Incorrect assumptions may be built in; does not account for income taxes, IRS tax code not changing etc.</li>
    <li>For this calculator we end calculations at age 60(Approximates end of IRS early withdraw penalty)</li>
    <li>The ROTH ladder avoids paying a 10% early IRA withdraw penalty by moving money from an IRA to a ROTH-IRA and not using this converted money for 5 years.</li>
    <dl>FIRE blogs (early retirement resources) for more information:       
      <dd><a href="https://forum.mrmoneymustache.com/taxes/roth-conversion-ladder-animated-diagram/">Mr Money Mustache forums on Roth Ladder, </a></dd>
      <dd><a href="https://www.mrmoneymustache.com/">Mr Money Mustache main site, </a></dd>
      <dd><a href="https://rootofgood.com/roth-ira-conversion-ladder-early-retirement/">Root of Good on Roth Ladder</a></dd>
    </dl>
    
    </p>
      </main>
      </div>
    );
  }
  // renderCalcResult(props) {
  //   return (
      
  //     <p id='calcResult'>{this.props.items}</p>
      
  //   );
  // }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
    {/* <NameForm /> */}
    {/* <CalcResultsElement /> */}
  </React.StrictMode>
  // <React>
  //   <NameForm />
  // </React>
  ,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
